<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <PageHeader></PageHeader>
    <a-card style="width: 96%; margin: 10px auto;margin-bottom: 20px">
      <a-form-model ref="refForm" class="ak-query-filter" :model="queryParams" :colon="false">
        <a-row :gutter="24">
          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="手机号" prop="phone">
              <a-input v-model="queryParams.phone" placeholder="请输入"/>
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="店铺名称" prop="shopName">
              <a-input v-model="queryParams.shopName" placeholder="请输入"/>
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="状态" prop="state">
              <ak-select :loadData="dict.type.COMPLAINT_STATUS" allowClear v-model="queryParams.state" placeholder="请选择"/>
            </a-form-model-item>
          </a-col>

          <a-col v-bind="$wrapperThreeCol">
            <a-form-model-item label="投诉时间" prop="dateRange">
              <a-range-picker v-model="queryParams.dateRange" show-time class="W100" :placeholder="['请选择','请选择']" format="YYYY-MM-DD"
                              valueFormat="YYYY-MM-DD"/>
            </a-form-model-item>
          </a-col>

          <a-col class="action-btn-main">
            <a-button type="primary" class="ML12" @click="handleQuery">查询</a-button>
            <a-button class="ML12" @click="handleReset">重置</a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>

    <a-table :pagination='pagination'
              @change="changePage"
             :columns="columns"
             :data-source="list"
             :scroll="{ x: 100 }"
             :rowKey="record => record.id"
             :loading="loading" style="width:96%; margin: 0 auto">

      <span slot="state" slot-scope="text">
        <span>{{dict.label.COMPLAINT_STATUS[text]}}</span>
      </span>

      <span slot="complaintTime" slot-scope="text">
        <span class="table-normal">{{$util.parseTime(text, '{y}-{m}-{d} {h}:{i}')}}</span>
      </span>

      <!-- 操作 -->
      <span slot="action" slot-scope="text, record">
        <template>
        <a v-if="isShowAction(record,'edit')" @click="handleEdit(record)" class="action">详情</a>
        <a v-if="isShowAction(record,'approve')" @click="handleApprove(record)" class="action">审核</a>
        </template>
      </span>
    </a-table>

    <!-- dialog -->
    <ApproveComp ref="modalApproveComp" :data="form" @handleOk="() => this.getList()"/>
  </ak-container>
</template>

<script>

  import * as ComplaintApi from '@/api/content/complaint.api'
  import ApproveComp from './approve-comp'

  const columns = [
    {
      title: '投诉人',
      dataIndex: 'complainterInfo.wechatName',
      width: '130px'
    },
    {
      title: '手机号',
      dataIndex: 'complainterInfo.phone',
      width: 130
    },
    {
      title: '投诉状态',
      dataIndex: 'state',
      width: 90,
      align: 'center',
      scopedSlots: {customRender: 'state'}
    },
    {
      title: '被投诉店铺名称',
      dataIndex: 'complaintShopResp.shopName',
      align: 'center',
      width: 195
    },
    {
      title: '举报类型',
      dataIndex: 'types',
      width: 140
    },
    {
      title: '投诉时间',
      dataIndex: 'complaintTime',
      defaultSortOrder: 'descend', // 默认上到下为由大到小的顺序
      sorter: (a, b) => { return a.complaintTime > b.complaintTime ? 1 : -1 },
      width: 140,
      scopedSlots: {customRender: 'complaintTime'}
    },
    {
      title: '处理结果',
      dataIndex: 'complaintResult',
      width: 300
    },
    {
      title: '操作',
      dataIndex: 'action',
      scopedSlots: {customRender: 'action'},
      width: '150px',
      fixed: 'right'
    }
  ];

  const actionShow = {
    'edit': [1, 2, 3],
    // 'approve': [1, 2, 3],
    'approve': [1],
  }

  export default {
    name: 'ComplaintList',
    components:{ApproveComp},
    dictTypes: [
      {
        dictType: 'COMPLAINT_STATUS',
        dataType: Number
      },
    ],
    data() {
      return {
        // 查询参数
        queryParams: {
          phone: null,
          shopName: null,
          state: null,
          complaintTimeStart: null,
          complaintTimeEnd: null,
          page: 1,
          pageSize: 10,
          dateRange: []
        },
        form: {
          id: null,
          state: null, // 审核状态 2已通过，3-未通过
          complaintResult: null,
        },
        pagination: {
          total: 0,
          pageSize: 10, // 每页中显示10条数据
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100"], // 每页中显示的数据
          showTotal: total => `共有 ${total} 条数据`, // 分页中显示总的数据
          current: 1
        },
        columns,
        list: [],
        loading: false,
      }
    },
    created() {
      this.getList()
    },
    methods: {

      // 根据状态显示操作按钮
      isShowAction(record, action) {
        return actionShow[action].includes(record.state);
      },

      // 列表API获取
      getList() {
        this.queryParams.complaintTimeStart = this.queryParams.dateRange[0] || null
        this.queryParams.complaintTimeEnd = this.queryParams.dateRange[1] || null
        ComplaintApi.search(this.queryParams).then(data => {
          console.log("=====>", data)
          this.list = data.list || []
          this.pagination.total = data.total || 0
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
  changePage(val){
    console.log(val)
    this.queryParams.page = val.current
    this.queryParams.pageSize = val.pageSize
    this.pagination.pageSize = val.pageSize
    this.pagination.current = val.current
    this.getList()
  },
      handleQuery() {
        this.queryParams.page = 1
        this.loading = true
        this.getList()
      },

      handleReset() {
        this.$refs['refForm'].resetFields()
      },

      // 详情
      handleEdit(record) {
        this.$router.push({
          name: 'ComplaintDetail',
          query: {
            mode: '2',
            id: record.id
          }
        })
      },
      // 审核
      handleApprove(record) {
        this.$refs.modalApproveComp.showModal(record)
      },
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .ant-card-body {
    padding: 18px 18px 8px 18px;
  }

  /deep/ .ant-col-xl-8 {
    padding-left: 0 !important;
  }
</style>
