<template>
  <a-modal v-model="visible"
           width="600px"
           :bodyStyle="{'height': '300px'}"
           :footer="false"
           :keyboard="false"
           :maskClosable="false"
           :destroyOnClose="true">
    <div slot="title" class="modal-title">
      <div class="title">投诉审核</div>
    </div>
    <div class="dlg-container">
      <a-form-model ref="refForm" :model="form" :rules="rules" layout="vertical" style="height: 200px; overflow: auto">
        <a-row class="form-row">
          <a-form-model-item label="投诉状态" prop="state">
            <a-radio-group v-model="form.state" style="width: 500px;">
              <a-radio v-for="(item, index) in dict.type.COMPLAINT_APPROVE_STATUS" :value="item.value" :key="index">
                {{item.label}}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-row>
        <a-row class="form-row">
          <a-form-model-item label="处理结果" prop="complaintResult">
            <a-input v-model="form.complaintResult" placeholder="请输入处理结果" :maxLength="200" style="width: 100%;"/>
          </a-form-model-item>
        </a-row>
      </a-form-model>
      <a-row :span="24" class="button-item" style="height: 50px;">
        <a-button type="primary" class="button" @click="handleConfirm">确认</a-button>
        <a-button class="button2" style="margin-left: 20px" @click="handleCancel">取消</a-button>
      </a-row>
    </div>
  </a-modal>
</template>
<script>
  import * as ComplaintApi from '@/api/content/complaint.api'

  export default {
    name: 'ComplaintApproveComp',
    dictTypes: [
      {
        dictType: 'COMPLAINT_APPROVE_STATUS',
        dataType: Number
      },
    ],
    data() {
      return {
        visible: false,
        form: {
          id: null,
          state: null, // 审核状态 2已通过，3-未通过
          complaintResult: null,
        },
        rules: {
          state: [
            {
              required: true,
              message: '投诉状态必须选择',
              trigger: 'change'
            }
          ],
          complaintResult: [
            {
              required: true,
              message: '处理结果必须输入',
              trigger: 'blur'
            }
          ],
        }
      }
    },
    methods: {
      handleConfirm() {
        this.$refs['refForm'].validate((valid) => {
          if (valid) {
            if (this.loading) return
            this.loading = true
            ComplaintApi.approve(this.form).then(data => {
              this.loading = false
              this.$modal.msg("操作成功")
              this.$emit('handleOk', data)
              this.visible = false
            }).catch(err => {
              this.loading = false
            })
          }
        })
      },
      showModal(form) {
        this.form.id = form.id;
        this.form.state = null;
        this.form.complaintResult = null;
        this.visible = true;
      },
      handleCancel() {
        this.visible = false
      },
    }
  }
</script>

<style scoped lang="less">
  .dlg-container {
    height: 100%;
    padding: 0 10px 10px 10px;
    overflow: auto;

    .form-row {
    }

    .button-item {
      width: 100%;
      position: absolute;
      /*top: 20px;*/
      left: 20px;
      right: 20px;
      bottom: 20px;
      /*margin-top: 50px;*/
      text-align: center;

      .button {
        font-size: 16px;
        width: 200px;
        height: 40px;
        line-height: 40px;
      }

      .button2 {
        font-size: 16px;
        width: 100px;
        height: 40px;
        line-height: 40px;
      }
    }

  }

</style>
